<template>
  <transition-group name="f-slide-fade">
    <core-contextual-error-box v-if="error" key="error" :action="refresh" />

    <file-list-grid
      key="list-grid"
      v-else
      :files="files"
      :files-store-id="filesStoreId"
      :group-files="sortOption.isGrouped && !collectionDescriptor?.search"
      :disable-approach-bottom="isPageQueued || !hasMore"
      :disable-selection="disableSelection"
      :disable-context-menu="disableContextMenu"
      :disable-back-to-top="disableBackToTop"
      @approach-bottom="getMore"
      @list-item-click="item => emit('list-item-click', {item})"
    >

      <template #header>
        <slot name="header" />
      </template>

      <template #under-header>

        <!-- note: this needs to use v-show because otherwise there are temporary states that cause file-list-control and by extension file-filter-panel to be unmounted and remounted -->
        <div v-show="(!isEmpty || isEmptySearch) && !disableToolbar" class="z-10 mr-[1px] bg-white pr-[2px] lg:mr-[18px] lg:pr-[5px] isolate transition-none sticky top-0">
          <file-list-controls class="pb-2" :files-store-id="filesStoreId">
            <slot name="controls" />
          </file-list-controls>

          <div
            class="absolute -inset-0.5 bg-white/50 backdrop-blur-[2px] z-10 transition-opacity duration-300"
            :class="selection.hasAny.value ? 'opacity-1 pointer-events-auto': 'opacity-0 pointer-events-none'"
          />
        </div>

        <div v-if="isEmptySearch">
          <core-empty-state
            heading="No Results"
            description="No files were found that match your search criteria."
            :icon="COMMON_ICONS.noFiles"
          />
        </div>

        <div v-else-if="isEmpty">
          <slot name="empty-state">
            <core-empty-state
              heading="No Files"
              description="No files in this container."
              :icon="COMMON_ICONS.noFiles"
            />
          </slot>
        </div>

      </template>
    </file-list-grid>
  </transition-group>
</template>

<script setup>
  import {storeToRefs} from 'pinia';
  import {makeFilesStore} from '~/stores/files.js';
  import {useStorage} from '@vueuse/core';

  const emit = defineEmits(['list-item-click']);
  const props = defineProps({
    filesStoreId: String,
    userId: String,
    context: Object,
    contextType: String,
    shareToken: String,
    addedSince: String,
    disableSelection: Boolean,
    disableToolbar: Boolean,
    loadingText: {
      type: String,
      default: 'Loading Files'
    },
    disableContextMenu: Boolean,
    disableBackToTop: Boolean
  });

  const selection = useSelection();
  const filesStore = props.filesStoreId
    ? makeFilesStore(props.filesStoreId)()
    : useFilesStore();

  const {files, hasMore, isPageQueued, collectionDescriptor, areFiltersApplied, isLoadingFiles} = storeToRefs(filesStore);
  const sortOption = computed(() => collectionDescriptor.value?.order || FILE_DEFAULT_SORT_OPTION);

  const route = useRoute();
  const router = useRouter()

  const {refresh, error, pending} = await useLazyAsyncData(
    `files-${props.context?.id || 'all'}`,
    async () => {
      if (props.context?.id
        && collectionDescriptor.value?.contextId === props.context?.id
        && props.addedSince === collectionDescriptor.value?.addedSince) {

        //note: this has already been loaded - no need to reload
        return;
      }

      const order = (() => {
        if (props.contextType === FILE_CONTEXTS.deleteBin) {
          return FILE_SORT_OPTION_DELETED;
        }

        if (props.context) {
          return FILE_SORT_OPTIONS_WITH_CUSTOM.find(so => so.paramName === props.context.file_sort) || FILE_DEFAULT_SORT_OPTION;
        }

        const qsSort = route.query?.order;
        const storedSort = useStorage(FILE_FILTER_PANEL_SORT_STORAGE_KEY).value;
        const requestedSort = qsSort || storedSort;

        if (requestedSort) {
          return FILE_SORT_OPTIONS_WITH_CUSTOM.find(so => so.paramName === requestedSort);
        }

        return FILE_DEFAULT_SORT_OPTION;
      })();

      const organization = [];

      if (route.query?.filter === 'favorites') {
        organization.push(FILE_FILTERS.favorites);
      }

      router.replace({
        query: {
          ...route.query,
          order: undefined,
          filter: undefined
        }
      });

      return await filesStore.getFiles({
        order,
        organization,
        userId: props.userId,
        shareToken: props.shareToken,
        contextType: props.contextType,
        context: props.context,
        addedSince: props.addedSince
      });
    },
    {
      server: false,
      watch: [() => props.addedSince]
    }
  );

  const isLoading = computed(() => pending.value || isLoadingFiles.value);
  const isEmpty = computed(() => !files.value?.length && !pending.value);
  const isEmptySearch = computed(() => isEmpty.value && (collectionDescriptor.value?.search || areFiltersApplied.value));

  const getMoreError = ref(null);

  async function getMore() {
    if (!hasMore.value) {
      return;
    }

    try {
      await filesStore.getFiles({nextPage: true}); //todo: see if we can remember sort for subsequent pages
    } catch (e) {
      getMoreError.value = e; //todo: what do we do?
    }
  }

</script>
