<template>
  <div class="h-full relative bg-gray-200/75 group cursor-pointer flex-center select-none" @contextmenu.prevent="onContextMenuClick" v-on-long-press="onLongPress" @click="onClick">

    <!--todo: disabled state??? since we are reworking the album ordering, let's figure that out then -->

    <u-icon v-if="!hasImage || error" :name="COMMON_ICONS.photo" class="absolute-center z-0 text-charcoal opacity-30 w-16 h-16" key="place" />
    <div
      v-else
      class="absolute z-0 bg-no-repeat bg-center transition-all"
      :style="{backgroundImage: `url(${src})`}"
      :class="[
        selected ? 'inset-2 rounded-lg' : 'inset-0',
        bgContain || file.is.project ? 'bg-contain' : 'bg-cover'
      ]"
    />

    <!--selection top shadow-->
    <div
      class="pointer-events-none absolute inset-0 opacity-0 transition-opacity z-10 duration-300"
      :class="{
        'group-hover:opacity-100 bg-gradient-to-b from-black/20 to-30%': !selectionCandidate && !selected,
        'opacity-100': !selected && !selectionCandidate && hasAny
      }"
    />

    <!--selection candidate overlay (shft-select)-->
    <div
      class="pointer-events-none absolute inset-0 opacity-0 transition-opacity z-20"
      :class="{'opacity-50 bg-blue-300': selectionCandidate}"
    />

    <!--file type decorator-->
    <template v-if="decorator.isDisplayed">
      <div class="absolute bottom-0 left-0 px-1.5 py-1 flex gap-0.5 bg-white/60 text-charcoal items-center rounded-tr-md z-30">
        <u-icon :name="decorator.icon" class="w-4 h-4" />
        <span class="text-xs font-semibold leading-none pt-px">{{decorator.text}}</span>
      </div>
    </template>

    <!--selection toggle-->
    <core-select-toggle v-if="selectable" class="absolute top-1 left-1 z-50" v-model:selected="selected" />

    <!--info-->
<!--
    <u-tooltip class="absolute bottom-1 left-1" :ui="{container: 'flex z-[999]', base: 'h-auto z-[999]'}">
      <template #text>
        {{file.name}}<br>
        {{file.width}}x{{file.height}}
      </template>
      <u-icon
        name="i-ri-information-fill"
        class=" text-white w-7 h-7 opacity-0 group-hover:opacity-60 hover:!opacity-100 transition-all duration-300"
      />
    </u-tooltip>
-->


    <!--favorite-->
    <template v-if="file.favorite">
      <u-icon :name="COMMON_ICONS.favoriteFill" class="absolute bottom-1 right-1 text-white z-30 w-7 h-7" />
    </template>

    <!--bottom shading - only show when we have an image-->
    <div
      :class="hasImage && !isLoading && !error ? 'opacity-100' : 'opacity-0'"
      class="pointer-events-none absolute inset-0 z-10 bg-gradient-to-t from-black/15 to-30% transition-opacity duration-1000"
    />
  </div>
</template>

<script setup>
  import {useImage} from '@vueuse/core';
  import { vOnLongPress } from '@vueuse/components'
  const {hasAny} = useSelection();
  const emit = defineEmits(['item-context', 'click']);


  const props = defineProps({
    file: Object,
    selectable: {
      type: Boolean,
      default: true
    },
    bgContain: Boolean,
    selectionCandidate: Boolean
  });

  const selected = defineModel('selected');

  const previewImg = computed(() => props.file.images?.preview);
  const hasImage = computed(() => Boolean(previewImg.value));
  const isGif = props.file.is.gif;
  const rotation = computed(() => {
    const fileRotation = props.file.images?.rotation || 0;

    return ((fileRotation % 360) + 360) % 360; //note ensure rotation is always a positive number
  });

  ///todo: move to mapper
  const src = computed(() => {
    if (isGif) {
      return updateQueryParameters(previewImg.value, {
        height: null,
        quality: null,
        format: null
      });
    } else {
      const dimensionQuery = (rotation.value || 0) % 180
        ? {width: 350}
        : {height: 350};

      return updateQueryParameters(previewImg.value, {
        ...dimensionQuery,
        interlace: true,
        quality: 60,
        strip_exif: true,
        format: 'jpg',
        rotate: rotation.value
      });
    }
  });

  const {isLoading, error} = useImage({src: src.value});

  const decorator = computed(() => {
    let icon;
    let text;

    if (props.file.is.document) {
      icon = 'i-ri-attachment-2';
      text = 'PDF'
    } else if (props.file.is.project) {
      icon = 'i-ri-brush-line';
      text = 'PROJECT'
    } else if (props.file.is.video) {
      icon = 'i-ri-play-fill';
      text = formatDuration(props.file.duration)
    } else if (props.file.is.audio) {
      icon = 'i-ri-volume-up-fill';
      text = formatDuration(props.file.duration)
    }

    return {
      isDisplayed: !props.file.is.photo,
      icon,
      text
    }
  });

  const selection = useSelection();

  function onContextMenuClick() {
    if (!selection.hasAny.value) {
      emit('item-context');
    }
  }

  const isLongPressOn = ref(false);

  function onLongPress() {
    emit('item-context');
    isLongPressOn.value = true;
  }

  function onClick() {
    if (isLongPressOn.value) {
      isLongPressOn.value = false;
    } else {
      emit('click');
    }
  }
</script>
